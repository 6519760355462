
/*----------  vari  ----------*/

.mainLogo, .footerLogo {
  width: 120px;
}
.titleImg {
  width: auto !important;
  height: 200px;
}
.blog-img-2{
  text-align: center;
}
.titleImgDiv>img{
  width:70%;
}
.titleImgDiv{
  text-align:center;
}
.blogContent{
  margin-top: 20px;
}
.blogContent table {
  margin: 10px;
  width: 100%;
}
.blogContent table tbody tr td {
  background-color: #012456;
  color: #ffffff;
  border-color: black;
  border-width: 0.5px;
  padding: 10px;
  margin: 10px;
}
.bannerImg{
  opacity:0.7 !important;
}
@font-face {
  font-family: 'TAEBAEKfont';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/TAEBAEKfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Orbit-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/Orbit-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TheJamsil5Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/TheJamsil5Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
.single-banner .banner-content h3, .single-banner .banner-content .h3 {
  color: black !important;
  //font-family: '' !important;
  font-weight: 100 !important;
  font-style: normal !important;
}